

function PageBuilder() {

  var dropTargetElement, sidebarElement;
  this.envURL = '';
  const self = this;

  // Initialize the builder functions
  this.initialize = function (params) {
    this.options = {
      builderSectionId: "builder",
      builderSidebarId: "right-sidebar"
    }

    this.envURL = params.envURL;
    // Set custom drop target id, right sidebar id in case they are not set as attributes
    if (params.builderSectionId) {
      this.options.builderSectionId = params.builderSectionId;
    } else {
      this.options.builderSectionId = "builder";
    }

    this.builderElement = document.getElementById(this.options.builderSectionId);
    this.initializeHTML(this.options.builderSectionId);

    this.initializeVariables();

    self.addDefaultElementsToDropTarget(params);
    this.addDragstartHandler();
    this.addDragoverHandler();
    this.addDragleaveHandler();
    self.addDropHandler();
    self.addAlignmentChangeHandler();
    this.addFontSizeChangeHandler();
    self.addfontStyleChangeHandler();
    self.addWidthChangeHandler();
    this.addHeightChangeHandler();
    this.addImagePositionChangeHandler();
    this.addWeightChangeHandler();
    self.addMarginChangeHandler();
    this.addSectionColorChangeHandler();
    self.addTextColorChangeHandler();
    self.moveElementsChangeHandler();
    document.getElementById('weight-option').classList.add("hidden");

    let dropTarget = document.getElementById('drop-target');
    dropTarget.addEventListener("click", (event) => {
      if (event.target.classList.contains('drop-target')) {
        let selectedElement = document.querySelector('.selected');
        if (selectedElement) {
          selectedElement.classList.remove('selected');
          this.selectedElement = null;
          document.getElementById('align-items-options').classList.remove("hidden");
          document.getElementById('font-size-options').classList.remove("hidden");
          document.getElementById('font-style-options').classList.remove("hidden");
          document.getElementById('text-color-options').classList.remove("hidden");
          document.getElementById('section-color-options').classList.remove("hidden");
          document.getElementById('height-width-options').classList.remove("hidden");
          document.getElementById('margin-options').classList.remove("hidden");
          document.getElementById('global-margin-options').classList.remove("hidden");

        }
        self.removeSwapElements();
      }
    });

    dropTarget.addEventListener("paste", function (e) {
      // cancel paste
      e.preventDefault();

      // get text representation of clipboard
      var text = (e.originalEvent || e).clipboardData.getData('text/plain');

      // insert text manually
      document.execCommand("insertHTML", false, text);
    });


  };

  this.initializeVariables = () => {
    dropTargetElement = "drop-target";
    sidebarElement = this.options.builderSidebarId;

    // Drop area element
    this.dropTarget = this.builderElement.querySelector('#drop-target');

    // Right sidebar for styling element
    this.sidebar = this.builderElement.querySelector('#right-sidebar');

    // Alignment options for elements
    this.alignmentRadios = this.sidebar ? this.sidebar.querySelectorAll('input[name="alignment"]') : [];
    this.alignmentOptions = ["left", "center", "right"];

    // Font style options for elements
    this.fontStyleCheckbox = this.sidebar ? this.sidebar.querySelectorAll('input[name="style"]') : [];
    this.fontStyleOptions = ["none", "bold", "italic", "underline"];

    // Align element on the left side by default
    this.alignmentIndex = 0;
    this.fontStyleIndex = -1;
    this.selectedElement = null;

    // Font size increase and decrease buttons
    this.fontSizeSelect = this.sidebar ? this.sidebar.querySelector("#font-size-select") : null;
  }

  // Add default elements to the drop target
  this.addDefaultElementsToDropTarget = (params) => {

    // Check if elements are specified in the options
    let elementList = ['image', 'img', 'h1', 'h2', 'h3', 'heading', 'paragraph', 'p', 'hr', '50:50', '30:70', '70:30'];
    if (params.elements && Array.isArray(params.elements)) {

      params.elements.forEach(function (elementData) {
        var element;
        var dropItem = document.createElement("div");
        dropItem.classList.add("drop-item");
        // dropItem.setAttribute("contenteditable", "true");
        let column1, column2;
        switch (elementData.type) {
          case "image":
          case "img":
            element = document.createElement("label");
            var element_image = document.createElement('img');
            element.appendChild(element_image);
            element_image.src = elementData.url;
            element.classList.add('img-label');

            let imageInput = document.createElement('input');
            imageInput.type = "file";
            imageInput.className = "image-upload";
            imageInput.name = "image-upload";
            imageInput.accept = "file";
            imageInput.addEventListener("change", this.imageChangeHandler.bind(this));
            element.appendChild(imageInput);

            let imageText = document.createElement("p");
            imageText.className = "img-text";
            imageText.innerHTML = "Click here to upload an image";
            element.appendChild(imageText);
            element.setAttribute("contenteditable", "true");
            dropItem.appendChild(element);
            dropItem.setAttribute("data-attribute", "image");
            dropItem.addEventListener("click", function () {
              // self.swapElementHandler(dropItem);
              this.selectElement(dropItem);
            }.bind(this));
            break;
          case "h1":
            element = document.createElement("h1");
            element.innerHTML = elementData.displayText ?? "";
            element.setAttribute("contenteditable", "true");

            dropItem.appendChild(element);
            dropItem.setAttribute("data-attribute", "h1");
            element.setAttribute("data-placeholder", "Heading 1");

            dropItem.addEventListener("click", function () {
              // self.swapElementHandler(dropItem);
              this.selectElement(dropItem);
            }.bind(this));
            break;
          case "h2":
          case "heading":
            element = document.createElement("h2");
            element.innerHTML = elementData.displayText ?? "";
            // self.addStyleToDefaultElementsInDropTarget(element, elementData.styling);
            element.setAttribute("data-placeholder", "Heading 2");
            element.setAttribute("contenteditable", "true");
            dropItem.appendChild(element);
            dropItem.setAttribute("data-attribute", "h2");
            dropItem.addEventListener("click", function () {
              // self.swapElementHandler(dropItem);
              this.selectElement(dropItem);
            }.bind(this));
            break;
          case "h3":
            element = document.createElement("h3");
            element.innerHTML = elementData.displayText ?? "";
            element.setAttribute("data-placeholder", "Heading 3");
            element.setAttribute("data-attribute", "h3");
            element.setAttribute("contenteditable", "true");
            dropItem.appendChild(element);
            dropItem.setAttribute("data-attribute", "h3");
            dropItem.addEventListener("click", function () {
              // self.swapElementHandler(dropItem);
              this.selectElement(dropItem);
            }.bind(this));
            break;
          case "paragraph":
          case "p":
            element = document.createElement("p");
            element.innerHTML = elementData.displayText ?? "";
            element.setAttribute("contenteditable", "true");
            dropItem.appendChild(element);
            dropItem.setAttribute("data-attribute", "paragraph");
            element.setAttribute("data-placeholder", "Paragraph");
            dropItem.addEventListener("click", function () {
              // self.swapElementHandler(dropItem);
              this.selectElement(dropItem);
            }.bind(this));
            break;
          case "hr":
            let hrContainer = document.createElement('div');

            let hrElement = document.createElement('hr');
            hrElement.classList.add('hr-element');

            if (elementData.color) {
              hrElement.style.backgroundColor = elementData.color;
            }

            if (elementData.width) {
              hrElement.style.width = elementData.width;
            }

            if (elementData.textAlign) {
              hrElement.setAttribute('align', elementData.textAlign);
            }

            if (elementData.weight) {
              let hrTags = this.dropTarget.querySelectorAll('hr');
              console.log(elementData.weight);
              if (elementData.weight == 1) {
                hrTags.forEach((tag) => {
                  tag.style.height = "2.5px";
                });
                document.getElementById("line-weight")[1].selected = 'selected';
              } else {
                hrTags.forEach((tag) => {
                  tag.style.height = "1px";
                });
                document.getElementById("line-weight")[0].selected = 'selected';
              }
            }

            // Append the <hr> element to the container
            hrContainer.appendChild(hrElement);

            // Set the attribute and data-attribute values
            dropItem.setAttribute('data-attribute', "hr");
            hrContainer.setAttribute('data-attribute', "hr");

            // Add event listeners for selection and deletion
            hrContainer.addEventListener('click', function () {
              self.selectElement(hrContainer);
              // self.swapElementHandler(dropItem);
            }.bind(this));
            hrContainer.setAttribute("contenteditable", "true");
            dropItem.appendChild(hrContainer);

            break;
          case "50:50":
            element = document.createElement('div');
            column1 = document.createElement('div');
            column2 = document.createElement('div');

            element.classList.add('grid-row');
            column1.classList.add('col-50');
            column2.classList.add('col-50');

            // element.innerHTML = elementData.displayText ?? "<br>";
            column1.setAttribute('data-placeholder', 'Column 1');
            column2.setAttribute('data-placeholder', 'Column 2');
            element.setAttribute("data-handle", true);

            console.log(elementData.column1);
            self.addDefaultElementsToColumn(elementData.column1, column1);
            self.addDefaultElementsToColumn(elementData.column2, column2);
            element.appendChild(column1);
            element.appendChild(column2);
            dropItem.setAttribute("data-attribute", "50:50");
            dropItem.appendChild(element);
            break;
          case "70:30":
            element = document.createElement('div');
            column1 = document.createElement('div');
            column2 = document.createElement('div');

            element.classList.add('grid-row');
            column1.classList.add('col-70');
            column2.classList.add('col-30');

            // element.innerHTML = elementData.displayText ?? "<br>";
            column1.setAttribute('data-placeholder', 'Column 1');
            column2.setAttribute('data-placeholder', 'Column 2');
            element.setAttribute("data-handle", true);

            console.log(elementData.column1);
            self.addDefaultElementsToColumn(elementData.column1, column1);
            self.addDefaultElementsToColumn(elementData.column2, column2);
            element.appendChild(column1);
            element.appendChild(column2);
            dropItem.setAttribute("data-attribute", "70:30");
            dropItem.appendChild(element);
            break;
          case "30:70":
            element = document.createElement('div');
            column1 = document.createElement('div');
            column2 = document.createElement('div');

            element.classList.add('grid-row');
            column1.classList.add('col-30');
            column2.classList.add('col-70');

            // element.innerHTML = elementData.displayText ?? "<br>";
            column1.setAttribute('data-placeholder', 'Column 1');
            column2.setAttribute('data-placeholder', 'Column 2');
            element.setAttribute("data-handle", true);

            console.log(elementData.column1);
            self.addDefaultElementsToColumn(elementData.column1, column1);
            self.addDefaultElementsToColumn(elementData.column2, column2);
            element.appendChild(column1);
            element.appendChild(column2);
            dropItem.setAttribute("data-attribute", "30:70");
            dropItem.appendChild(element);
            break;
          default:
            break;
        }

        if (elementList.includes(elementData.type)) {

          // Set styling properties if provided
          if (elementData.styling && typeof elementData.styling === "object") {

            if (elementData.type == "img" || elementData.type == "image") {
              Object.entries(elementData.styling).forEach(function ([key, value]) {

                switch (key) {
                  case "textAlign":
                    element.parentElement.style[key] = value;
                    break;
                  case "marginLeft":
                  case "marginRight":
                  case "marginTop":
                  case "marginBottom":
                    element_image.style[key] = value;
                    break;
                  case "position":
                    if (value == "behind") {
                      dropItem.style.position = "absolute";
                      dropItem.style.opacity = "0.7";
                    }
                  default:
                    element_image.style[key] = value;
                    break;
                }
              });
            } else {
              Object.entries(elementData.styling).forEach(function ([key, value]) {
                element.style[key] = value;
              });
            }
          }

          // Set classes to element if provided
          // if (elementData.class) {
          //   if (element && typeof elementData.class == "string") {
          //     element.classList.add(elementData.class);
          //   } else {
          //     elementData.class.forEach((elementClass) => {
          //       if (element) {
          //         element.classList.add(elementClass);
          //       }
          //     });
          //   }
          // }

          // Set paragraph styling properties if provided
          if (elementData.paragraph_styling && typeof elementData.paragraph_styling === "object") {
            Object.entries(elementData.paragraph_styling).forEach(function ([key, value]) {
              element.style.setProperty("--" + key, value);
            });
          }

          // Generate a unique ID for the dropped element
          var uniqueId = "drop-item" + Date.now();
          dropItem.setAttribute("id", uniqueId);

          // Append delete icon to the dropped element
          var deleteIcon = document.createElement("i");
          deleteIcon.classList.add("fa", "fa-trash", "delete-icon", "fa-lg", "cursor-pointer");
          deleteIcon.setAttribute("title", "Remove");
          deleteIcon.setAttribute("contenteditable", "false");
          deleteIcon.addEventListener("click", function () {
            if (dropItem.classList.contains('selected')) {
              this.selectedElement = null;
              dropItem.classList.remove('selected');
            }
            dropItem.remove();
          });

          // Show delete icon only when hovering over the specific element
          dropItem.addEventListener("mouseover", function () {
            deleteIcon.style.display = "block";
          });

          dropItem.addEventListener("mouseout", function () {
            deleteIcon.style.display = "none";
          });

          dropItem.appendChild(deleteIcon);

          dropItem.addEventListener("keydown", function (event) {
            if (event.key === "Enter") {
              event.preventDefault();
              console.log(dropItem, 'dropItem3');
              // Insert a line break
              document.execCommand("insertLineBreak");
            }
          });

          // elementData.type
          let placeholderTags = ['paragraph', 'heading', 'h3', 'h2', 'h1', '50:50', '70:30', '30:70'];
          let columnTags = ['paragraph', 'heading', 'h3', 'h2', 'h1', '50:50', '70:30', '30:70'];
          dropItem.addEventListener("keyup", function (event) {
            console.log(event, 'text');
            if (event.target.getAttribute("data-attribute") && placeholderTags.includes(elementData.type)) {
              if (event.target.textContent == "") {
                switch (event.target.getAttribute("data-attribute")) {
                  case "paragraph":
                    event.target.querySelector("p").innerHTML = "";
                    break;
                  case "h1":
                    event.target.querySelector("h1").innerHTML = "";
                    break;
                  case "h2":
                  case "heading":
                    event.target.querySelector("h2").innerHTML = "";
                    break;
                  case "h3":
                    event.target.querySelector("h3").innerHTML = "";
                    break;
                  case '50:50':
                  case '30:70':
                  case '70:30':
                    event.target.querySelectorAll("h3").innerHTML = "";
                    break;
                }
              }
            }

            if (columnTags.includes(event.target.getAttribute("data-attribute"))) {
              let h1 = event.target.querySelectorAll("h1");
              let h2 = event.target.querySelectorAll("h2");
              let h3 = event.target.querySelectorAll("h3");
              let pg = event.target.querySelectorAll("pg");

              event.target.querySelectorAll("h1").forEach((h1) => {
                if (h1.textContent == "") {
                  h1.innerHTML = "";
                }
              })

              event.target.querySelectorAll("h2").forEach((h1) => {
                if (h1.textContent == "") {

                  h1.innerHTML = "";
                }
              })

              event.target.querySelectorAll("h3").forEach((h1) => {
                if (h1.textContent == "") {
                  h1.innerHTML = "";

                }
              })

              event.target.querySelectorAll("p").forEach((h1) => {
                if (h1.textContent == "") {
                  h1.innerHTML = "";
                }
              })
            }
          });

          this.dropTarget.appendChild(dropItem);
        }
      }, this);
    }
  }

  this.addStyleToDefaultElementsInDropTarget = (element, styles) => {
    console.log(styles);
    Object.entries(styles).forEach(function ([key, value]) {
      console.log(key, value, 'kv');
      switch (key) {
        case "marginTop":
          element.style.setProperty("margin-top", value, "important");
          break;
        case "marginBottom":
          element.style.setProperty("margin-bottom", value, "important");
          break;
        case "marginRight":
          element.style.setProperty("margin-right", value, "important");
          break;
        case "marginLeft":
          element.style.setProperty("margin-left", value, "important");
          break;
        default:
          console.log("default");
          break;
      }
    });
  }

  this.addDefaultElementsToColumn = function (params, parentColumn) {
    if (Array.isArray(params)) {

      params.forEach(function (elementData) {
        var element;
        var dropColumnItem = document.createElement("div");
        dropColumnItem.classList.add("drop-item");
        // dropColumnItem.setAttribute("contenteditable", "true");

        let elementList = ['image', 'img', 'h1', 'h2', 'h3', 'heading', 'paragraph', 'p', 'hr', '50:50', '30:70', '70:30'];

        switch (elementData.type) {
          case "image":
          case "img":
            element = document.createElement("label");
            var element_image = document.createElement('img');
            element.appendChild(element_image);
            element_image.src = elementData.url;
            element.classList.add('img-label');

            let imageInput = document.createElement('input');
            imageInput.type = "file";
            imageInput.className = "image-upload";
            imageInput.name = "image-upload";
            imageInput.accept = "file";
            imageInput.addEventListener("change", this.imageChangeHandler.bind(this));
            element.appendChild(imageInput);

            let imageText = document.createElement("p");
            imageText.className = "img-text";
            imageText.innerHTML = "Click here to upload an image";
            element.appendChild(imageText);

            element.setAttribute("contenteditable", "true");
            dropColumnItem.appendChild(element);
            dropColumnItem.setAttribute("data-attribute", "image");

            dropColumnItem.addEventListener("click", function () {
              this.selectElement(dropColumnItem);
              // self.swapElementHandler(dropColumnItem);
            }.bind(this));
            break;
          case "h1":
            element = document.createElement("h1");
            element.innerHTML = elementData.displayText ?? "<br>";
            element.setAttribute("data-placeholder", "Heading 1");
            dropColumnItem.setAttribute("data-attribute", "h1");
            dropColumnItem.addEventListener("click", function () {
              this.selectElement(dropColumnItem);
              // self.swapElementHandler(dropColumnItem);
            }.bind(this));
            element.setAttribute("contenteditable", "true");
            dropColumnItem.appendChild(element);
            break;
          case "h2":
          case "heading":
            element = document.createElement("h2");
            element.innerHTML = elementData.displayText ?? "<br>";
            element.setAttribute("data-placeholder", "Heading 2");
            dropColumnItem.setAttribute("data-attribute", "h2");
            element.setAttribute("contenteditable", "true");
            dropColumnItem.appendChild(element);
            dropColumnItem.addEventListener("click", function () {
              this.selectElement(dropColumnItem);
              // self.swapElementHandler(dropColumnItem);
            }.bind(this));
            break;
          case "h3":
            element = document.createElement("h3");
            element.innerHTML = elementData.displayText ?? "<br>";
            element.setAttribute("data-placeholder", "Heading 3");
            dropColumnItem.setAttribute("data-attribute", "h3");
            element.setAttribute("contenteditable", "true");
            dropColumnItem.appendChild(element);
            dropColumnItem.addEventListener("click", function () {
              this.selectElement(dropColumnItem);
              // self.swapElementHandler(dropColumnItem);
            }.bind(this));
            break;
          case "paragraph":
          case "p":
            element = document.createElement("p");
            element.innerHTML = elementData.displayText ?? "<br>";
            element.setAttribute("data-placeholder", "Paragraph");
            dropColumnItem.setAttribute("data-attribute", "paragraph");
            element.setAttribute("contenteditable", "true");
            dropColumnItem.appendChild(element);
            dropColumnItem.addEventListener("click", function () {
              this.selectElement(dropColumnItem);
              // self.swapElementHandler(dropColumnItem);
            }.bind(this));
            break;
          default:
            break;
        }

        if (elementList.includes(elementData.type)) {
          // Set styling properties if provided
          if (elementData.styling && typeof elementData.styling === "object") {

            if (elementData.type == "img" || elementData.type == "image") {
              Object.entries(elementData.styling).forEach(function ([key, value]) {
                switch (key) {
                  case "textAlign":
                    element.parentElement.style[key] = value;
                    break;
                  case "marginLeft":
                  case "marginRight":
                  case "marginTop":
                  case "marginBottom":
                    element_image.style[key] = value;
                    break;
                  default:
                    element_image.style[key] = value;
                    break;
                }
              });
            } else {
              Object.entries(elementData.styling).forEach(function ([key, value]) {
                element.style[key] = value;
                console.log(element.style[key], key, 'style');
              });
            }
          }

          // Set classes to element if provided
          // if (elementData.class) {
          //   if (typeof elementData.class == "string") {
          //     element.classList.add(elementData.class);
          //   } else {
          //     elementData.class.forEach((elementClass) => {
          //       element.classList.add(elementClass);
          //     });
          //   }
          // }

          // Set paragraph styling properties if provided
          if (elementData.paragraph_styling && typeof elementData.paragraph_styling === "object") {
            Object.entries(elementData.paragraph_styling).forEach(function ([key, value]) {
              element.style.setProperty("--" + key, value);
            });
          }

          // Generate a unique ID for the dropped element
          var uniqueId = "drop-item" + Date.now();
          dropColumnItem.setAttribute("id", uniqueId);

          // Append delete icon to the dropped element
          var deleteIcon = document.createElement("i");
          deleteIcon.classList.add("fa", "fa-trash", "delete-icon", "fa-lg", "pe-3", "cursor-pointer");
          deleteIcon.setAttribute("title", "Remove");
          deleteIcon.setAttribute("contenteditable", "false");
          deleteIcon.addEventListener("click", function () {
            if (dropColumnItem.classList.contains('selected')) {
              this.selectedElement = null;
              dropColumnItem.classList.remove('selected');
            }
            dropColumnItem.remove();
          });

          // Show delete icon only when hovering over the specific element
          dropColumnItem.addEventListener("mouseover", function () {
            deleteIcon.style.display = "block";
          });

          dropColumnItem.addEventListener("mouseout", function () {
            deleteIcon.style.display = "none";
          });

          dropColumnItem.appendChild(deleteIcon);

          dropColumnItem.addEventListener("click", function () {
            this.selectElement(dropColumnItem);
          }.bind(this));

          dropColumnItem.addEventListener("keydown", function (event) {
            if (event.key === "Enter") {
              console.log(dropColumnItem, 'dropColumnItem2');
              event.preventDefault();

              // Insert a line break
              document.execCommand("insertLineBreak");
            }
          });
          parentColumn.appendChild(dropColumnItem);
        }
      }, this);
    }
  }

  // Create a default drop target element and append it to the specified element ID
  this.initializeHTML = function (id) {

    // this.builderElement = document.createElement("div");
    this.builderElement.id = id;

    this.builderElement.innerHTML = `
    <div class="d-flex mx-2 builderParentDiv justify-content-between">
    <div class="left-sidebar bg-dark text-white px-2 overflow-auto">
        <div class="px-2 d-flex text-center align-items-center justify-content-between my-2">
            <div class="drag-item element-box me-1" title="H1" data-attribute="h1" draggable="true">
                <div>
                    <svg width="50px" height="50px" viewBox="0 0 20 20" version="1.1"
                        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g id="🔍-System-Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="ic_fluent_text_header_1_20_filled" fill="currentColor" fill-rule="nonzero">
                                <path
                                    d="M16.2082352,3.75097968 L16.3315,3.75438 C16.417,3.76362 16.4981,3.78721 16.5725,3.8227 C16.6798,3.87366 16.7713,3.94855 16.8418,4.03921 C16.92476,4.145898 16.978664,4.27441 16.9949616,4.41140328 L17,4.51553 L17,15.5 C17,15.9142 16.6642,16.25 16.25,16.25 C15.8703167,16.25 15.5565111,15.9678347 15.5068469,15.6017677 L15.5,15.5 L15.5,7.46849 C14.9718,8.09958 14.3432,8.67263 13.666,9.12404 C13.3214,9.35381 12.8557,9.26068 12.626,8.91603 C12.3962,8.57138 12.4893,8.10573 12.834,7.87597 C14.1257647,7.01476529 15.1346893,5.6621646 15.4611782,4.55734877 L15.5143,4.35328 C15.5276,4.28666 15.5496,4.22322 15.5792,4.16422 C15.6263,4.06986 15.6922,3.98817 15.771,3.92286 C15.89292,3.82182 16.045816,3.7599544 16.2082352,3.75097968 L16.2082352,3.75097968 Z M9.25,3.75001 C9.6296925,3.75001 9.94349042,4.03215847 9.99315332,4.39823809 L10,4.50001 L10,15.5 C10,15.9142 9.66421,16.25001 9.25,16.25001 C8.8703075,16.25001 8.55650958,15.9678363 8.50684668,15.6017679 L8.5,15.5 L8.5,10.5 L3.5,10.5 L3.5,15.5 C3.5,15.9142 3.16421,16.25001 2.75,16.25001 C2.3703075,16.25001 2.05650958,15.9678363 2.00684668,15.6017679 L2,15.5 L2,4.50001 C2,4.08579 2.33579,3.75001 2.75,3.75001 C3.1296925,3.75001 3.44349042,4.03215847 3.49315332,4.39823809 L3.5,4.50001 L3.5,9.00001 L8.5,9.00001 L8.5,4.50001 C8.5,4.08579 8.83579,3.75001 9.25,3.75001 Z"
                                    id="🎨-Color">

                                </path>
                            </g>
                        </g>
                    </svg>
                </div>
                <p class="m-0 ">Heading 1</p>
            </div>
            <div class="drag-item element-box ms-1" title="H2" data-attribute="h2" draggable="true">
                <div>
                    <svg width="50px" height="50px" viewBox="0 0 20 20" version="1.1"
                        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g id="🔍-System-Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="ic_fluent_text_header_2_20_filled" fill="currentColor" fill-rule="nonzero">
                                <path
                                    d="M9.25,3.75 C9.6296925,3.75 9.94349042,4.03215688 9.99315332,4.39823019 L10,4.5 L10,15.5 C10,15.9142 9.66421,16.25 9.25,16.25 C8.8703075,16.25 8.55650958,15.9678347 8.50684668,15.6017677 L8.5,15.5 L8.5,10.5 L3.5,10.5 L3.5,15.5 C3.5,15.9142 3.16421,16.25 2.75,16.25 C2.3703075,16.25 2.05650958,15.9678347 2.00684668,15.6017677 L2,15.5 L2,4.5 C2,4.08579 2.33579,3.75 2.75,3.75 C3.1296925,3.75 3.44349042,4.03215688 3.49315332,4.39823019 L3.5,4.5 L3.5,9 L8.5,9 L8.5,4.5 C8.5,4.08579 8.83579,3.75 9.25,3.75 Z M14.75,3.75 C16.1527,3.75 17.225,4.54308 17.7225,5.66463 C18.2133,6.77098 18.1329,8.15287 17.3931,9.38587 C17.0081,10.0276 16.4347,10.5441 15.8663,10.9916 L15.4600125,11.3022375 L15.4600125,11.3022375 L14.9835,11.6538 C14.6876,11.8712 14.4006,12.0833 14.1254,12.3039 C13.2332,13.0192 12.5563,13.7528 12.331,14.75 L17.25,14.75 C17.6642,14.75 18,15.0858 18,15.5 C18,15.9142 17.6642,16.25 17.25,16.25 L11.5,16.25 C11.0858,16.25 10.75,15.9142 10.75,15.5 C10.75,13.4013 11.9765,12.1043 13.1871,11.1336 C13.3891,10.9716667 13.5944778,10.8157778 13.7963296,10.6656667 L14.1577,10.3993 C14.4335,10.1967 14.6934,10.0059 14.9384,9.81304 C15.4715,9.39335 15.8669,9.01409 16.1069,8.61413 C16.6171,7.76379 16.6276,6.89569 16.3514,6.27287 C16.0818,5.66525 15.5292,5.25 14.75,5.25 C13.4578,5.25 12.5,6.37402 12.5,7.5 C12.5,7.91421 12.1642,8.25 11.75,8.25 C11.3358,8.25 11,7.91421 11,7.5 C11,5.62598 12.5513,3.75 14.75,3.75 Z"
                                    id="🎨-Color">
                                </path>
                            </g>
                        </g>
                    </svg>
                </div>
                <p class="m-0">Heading 2</p>
            </div>
        </div>
        <div class="px-2 d-flex text-center align-items-center justify-content-between my-2">
            <div class="drag-item element-box me-1" title="H3" data-attribute="h3" draggable="true">
                <div>
                    <svg width="50px" height="50px" viewBox="0 0 20 20" version="1.1"
                        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g id="🔍-System-Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="ic_fluent_text_header_3_20_filled" fill="currentColor" fill-rule="nonzero">
                                <path
                                    d="M9.25,3.75 C9.6296925,3.75 9.94349042,4.03215688 9.99315332,4.39823019 L10,4.5 L10,15.5 C10,15.9142 9.66421,16.25 9.25,16.25 C8.8703075,16.25 8.55650958,15.9678347 8.50684668,15.6017677 L8.5,15.5 L8.5,10.5 L3.5,10.5 L3.5,15.5 C3.5,15.9142 3.16421,16.25 2.75,16.25 C2.3703075,16.25 2.05650958,15.9678347 2.00684668,15.6017677 L2,15.5 L2,4.5 C2,4.08579 2.33579,3.75 2.75,3.75 C3.1296925,3.75 3.44349042,4.03215688 3.49315332,4.39823019 L3.5,4.5 L3.5,9 L8.5,9 L8.5,4.5 C8.5,4.08579 8.83579,3.75 9.25,3.75 Z M14.5,3.75 C15.7234,3.75 16.6178,4.24757 17.1951,4.92278 C17.7501,5.57201 18,6.37039 18,7 C18,7.55067 17.8687,8.48536 17.2384,9.29911 C17.0407,9.55432 16.8023,9.78699 16.5189,9.98768 C17.3046,10.4659 18,11.2656 18,12.5 C18,13.6607 17.6334,14.6148 16.9678,15.2803 C16.306,15.9422 15.4168,16.25 14.5,16.25 C13.6808,16.25 12.9021,16.1159 12.2704,15.7369 C11.6077,15.3392 11.178,14.7142 11.0146,13.8971 C10.9333,13.4909 11.1968,13.0958 11.6029,13.0146 C12.0091,12.9333 12.4042,13.1967 12.4854,13.6029 C12.572,14.0358 12.7673,14.2858 13.0421,14.4506 C13.348,14.6341 13.8192,14.75 14.5,14.75 C15.0833,14.75 15.569,14.5578 15.9072,14.2197 C16.2416,13.8852 16.5,13.3393 16.5,12.5 C16.5,11.8811 16.1509,11.4681 15.5596,11.1694 C14.9478,10.8604 14.1956,10.75 13.75,10.75 C13.3358,10.75 13,10.4142 13,10 C13,9.58579 13.3358,9.25 13.75,9.25 C15.0868,9.25 15.725,8.80344 16.0525,8.38058 C16.4086,7.92089 16.5,7.35558 16.5,7 C16.5,6.72336 16.3749,6.27174 16.055,5.89753 C15.7572,5.54931 15.2766,5.25 14.5,5.25 C13.6304,5.25 13.1816,5.46843 12.9374,5.67386 C12.6861,5.88533 12.556,6.16283 12.4705,6.4584 C12.3554,6.8563 11.9395,7.08556 11.5416,6.97046 C11.1437,6.85537 10.9145,6.4395 11.0295,6.0416 C11.1562,5.60383 11.401,5.00634 11.9717,4.52614 C12.5495,4.0399 13.3697,3.75 14.5,3.75 Z"
                                    id="🎨-Color">

                                </path>
                            </g>
                        </g>
                    </svg>
                </div>
                <p class="m-0 ">Heading 3</p>
            </div>
            <div class="drag-item element-box ms-1" title="Paragraph" data-attribute="paragraph"
                draggable="true">
                <div>
                <?xml version="1.0" encoding="UTF-8"?>
                <!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->
                <svg width="50px" height="50px" viewBox="0 0 24 24" version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g id="页面-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="Editor" transform="translate(-528.000000, -96.000000)">
                            <g id="paragraph_line" transform="translate(528.000000, 96.000000)">
                                <path d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5934901,23.257841 L12.5819402,23.2595131 L12.5108777,23.2950439 L12.4918791,23.2987469 L12.4918791,23.2987469 L12.4767152,23.2950439 L12.4056548,23.2595131 C12.3958229,23.2563662 12.3870493,23.2590235 12.3821421,23.2649074 L12.3780323,23.275831 L12.360941,23.7031097 L12.3658947,23.7234994 L12.3769048,23.7357139 L12.4804777,23.8096931 L12.4953491,23.8136134 L12.4953491,23.8136134 L12.5071152,23.8096931 L12.6106902,23.7357139 L12.6232938,23.7196733 L12.6232938,23.7196733 L12.6266527,23.7031097 L12.609561,23.275831 C12.6075724,23.2657013 12.6010112,23.2592993 12.5934901,23.257841 L12.5934901,23.257841 Z M12.8583906,23.1452862 L12.8445485,23.1473072 L12.6598443,23.2396597 L12.6498822,23.2499052 L12.6498822,23.2499052 L12.6471943,23.2611114 L12.6650943,23.6906389 L12.6699349,23.7034178 L12.6699349,23.7034178 L12.678386,23.7104931 L12.8793402,23.8032389 C12.8914285,23.8068999 12.9022333,23.8029875 12.9078286,23.7952264 L12.9118235,23.7811639 L12.8776777,23.1665331 C12.8752882,23.1545897 12.8674102,23.1470016 12.8583906,23.1452862 L12.8583906,23.1452862 Z M12.1430473,23.1473072 C12.1332178,23.1423925 12.1221763,23.1452606 12.1156365,23.1525954 L12.1099173,23.1665331 L12.0757714,23.7811639 C12.0751323,23.7926639 12.0828099,23.8018602 12.0926481,23.8045676 L12.108256,23.8032389 L12.3092106,23.7104931 L12.3186497,23.7024347 L12.3186497,23.7024347 L12.3225043,23.6906389 L12.340401,23.2611114 L12.337245,23.2485176 L12.337245,23.2485176 L12.3277531,23.2396597 L12.1430473,23.1473072 Z" id="MingCute" fill-rule="nonzero">

                                </path>
                                <path d="M18,3 C18.5523,3 19,3.44772 19,4 C19,4.51283143 18.613973,4.93550653 18.1166239,4.9932722 L18,5 L17,5 L17,19 L18,19 C18.5523,19 19,19.4477 19,20 C19,20.51285 18.613973,20.9355092 18.1166239,20.9932725 L18,21 L10,21 C9.44772,21 9,20.5523 9,20 C9,19.48715 9.38604429,19.0644908 9.88337975,19.0067275 L10,19 L11,19 L11,14 L9.5,14 C6.46243,14 4,11.5376 4,8.5 C4,5.53651707 6.34377632,3.12046068 9.27879259,3.0043672 L9.5,3 L18,3 Z M15,5 L13,5 L13,19 L15,19 L15,5 Z M11,5 L9.5,5 C7.567,5 6,6.567 6,8.5 C6,10.3685667 7.46427444,11.8951267 9.30796448,11.9948211 L9.5,12 L11,12 L11,5 Z" id="形状" fill="#fff">

                                </path>
                            </g>
                        </g>
                    </g>
                </svg>

                </div>
                <p class="m-0">Paragraph
                </p>
            </div>
        </div>
        <div class="px-2 d-flex text-center align-items-center justify-content-between my-2">
            <div class="drag-item element-box me-1" title="hr" data-attribute="hr" draggable="true">
                <div>
                    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="50px" height="50px"
                        viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">

                        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="currentColor"
                            stroke="none">
                            <path d="M0 2560 l0 -160 2560 0 2560 0 0 160 0 160 -2560 0 -2560 0 0 -160z" />
                        </g>
                    </svg>
                </div>
                <p id="drag-item5" class="m-0">Horizontal line</p>
            </div>

            <div class="drag-item element-box ms-1" title="Image" data-attribute="image" draggable="true">
                <div>
                    <svg viewBox="0 0 24 24" width="50px" height="50px">
                        <path fill="currentColor"
                            d="M21,3H3C2,3 1,4 1,5V19A2,2 0 0,0 3,21H21C22,21 23,20 23,19V5C23,4 22,3 21,3M5,17L8.5,12.5L11,15.5L14.5,11L19,17H5Z">
                        </path>
                    </svg>
                    <p class="img-text" style="display: none;">Click here to upload an image<span> (Max 2MB)</span></p>
                </div>
                <p class="m-0">Image</p>
            </div>
        </div>
        <div class="px-2 d-flex text-center align-items-center justify-content-between my-2">
            <div class="drag-item element-box me-1" title="50:50" data-attribute="50:50" draggable="true">
                <div>
                    <svg viewBox="0 0 23 24" width="50px" height="50px">
                        <path fill="currentColor"
                            d="M2 20h8V4H2v16Zm-1 0V4a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1ZM13 20h8V4h-8v16Zm-1 0V4a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1h-8a1 1 0 0 1-1-1Z">
                        </path>
                    </svg>
                </div>
                <p class="m-0">Columns (50:50)</p>
            </div>

            <div class="drag-item element-box ms-1" title="70:30"
                draggable="true" data-attribute="70:30">
                <div>
                  <svg viewBox="0 0 24 24" class="flip-svg" width="50px" height="50px">
                      <path fill="currentColor"
                          d="M2 20h5V4H2v16Zm-1 0V4a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1ZM10 20h12V4H10v16Zm-1 0V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H10a1 1 0 0 1-1-1Z">
                      </path>
                  </svg>
                </div>
                <p class="m-0">Columns (70:30)</p>
            </div>
        </div>
        <div class="px-2 d-flex text-center align-items-center justify-content-between my-2">
            <div class="drag-item element-box me-1" title="30:70"
                draggable="true" data-attribute="30:70">
                <div>
                  <svg viewBox="0 0 24 24" width="50px" height="50px">
                      <path fill="currentColor"
                          d="M2 20h5V4H2v16Zm-1 0V4a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1ZM10 20h12V4H10v16Zm-1 0V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H10a1 1 0 0 1-1-1Z">
                      </path>
                  </svg>
                </div>
                <p class="m-0">Columns (30:70)</p>
            </div>
            <div></div>
        </div>

    </div>
    <div class="drop-target" id="drop-target"></div>

    <div class="right-sidebar col-2" id="right-sidebar">

        <div id="move-elements-options" class="mb-4">
            <label class="fw-bold mb-2">Move Element:</label>
            <div class="d-flex">
                <div class="me-3">
                  <div class="cursor-pointer">
                    <i class="fa fa-hand-o-up fa-lg me-2" id="move-up" title="Move Element Up"></i>
                  </div>
                </div>
                <div class="me-4 cursor-pointer">
                  <i class="fa fa-hand-o-down fa-lg me-2" id="move-down" title="Move Element Down"></i>
                </div>
            </div>
        </div>

        <div id="align-items-options" class="mb-2">
            <label class="fw-bold mb-2">Align Item:</label>
            <div class="d-flex">
                <div class="align-option me-4">
                    <input type="radio" name="alignment" id="alignment-left" value="left" checked
                        class="display-none">
                    <label for="alignment-left"   title="Left" class="cursor-pointer mb-0"><i class="fa fa-align-left fa-lg"
                            aria-hidden="true"></i></label>
                </div>
                <div class="align-option me-4">
                    <input type="radio" name="alignment" id="alignment-center" value="center" class="display-none">
                    <label for="alignment-center" title="Center" class="cursor-pointer mb-0"><i class="fa fa-align-center fa-lg"
                            aria-hidden="true"></i></label>
                </div>
                <div class="align-option me-4">
                    <input type="radio" name="alignment" id="alignment-right" value="right" class="display-none">
                    <label for="alignment-right" title="Right" class="cursor-pointer mb-0"><i class="fa fa-align-right fa-lg"
                            aria-hidden="true"></i>
                    </label>
                </div>
            </div>
        </div>


        <div class="font-size-option mb-4" id="font-size-options">
            <label for="font-size-select" class="fw-bold mb-2">Font Size:</label>
            <select id="font-size-select" class="w-100 rounded p-1">
                <option value="10" selected>Select font size</option>
                <option value="10">10px</option>
                <option value="11">11px</option>
                <option value="12">12px</option>
                <option value="14">14px</option>
                <option value="16">16px</option>
                <option value="18">18px</option>
            </select>
        </div>

        <div class="mb-3 " id="font-style-options">
            <label class="fw-bold mb-2">Font style:</label>
            <div class="d-flex gap-10">
                <div class="font-style-option">
                    <input type="checkbox" name="style" class="display-none" id="style-bold" value="bold">
                    <label for="style-bold" title="Bold" class="cursor-pointer"><i class="fa fa-bold fa-lg me-2"></i></label>
                </div>
                <div class="font-style-option">
                    <input type="checkbox" name="style" class="display-none" id="style-italic" value="italic">
                    <label for="style-italic"  title="Italic" class="cursor-pointer"><i class="fa fa-italic fa-lg me-2"></i></label>
                </div>
                <div class="font-style-option">
                    <input type="checkbox" name="style" class="display-none" id="style-underline" value="underline">
                    <label for="style-underline"  title="Underline" class="cursor-pointer"><i
                            class="fa fa-underline fa-lg me-2"></i></label>
                </div>
            </div>
        </div>

        <div class="d-flex w-100">
            <div class="w-50 mt-2 mb-3 me-2" id="text-color-options">
                <label class="fw-bold mb-2">Text</label>
                <input type="color" class="w-100" value="#000000" id="text-color-picker">
            </div>

            <div class="w-50 mt-2 mb-3 ms-2" id="section-color-options">
                <label class="fw-bold mb-2">Section</label>
                <input type="color" class="w-100" id="bg-color-picker">
            </div>
        </div>

        <div class="d-flex mb-3 mt-4" id="height-width-options">
            <div class="me-1">
                <label class="fw-bold mb-2">Width:</label>
                <input type="number" id="width-input" class="w-100 rounded p-1 input-border">
            </div>
            <div class="ms-1" id="height-option">
                <label class="fw-bold mb-2">Height:</label>
                <input type="number" id="height-input" class="w-100 rounded p-1 input-border">
            </div>
        </div>

        <div class="d-flex mt-2" id="image-position-option">
          <div class="me-1">
          <label class="fw-bold mb-2">Image Position:</label>
                    <select id="image-position" class="w-100 rounded p-1">
                      <option value="default" selected disabled class="opt-disabled">Image Position</option>
                      <option value="in-front">In front</option>
                      <option value="behind">Behind</option>
                    </select>
          </div>
        </div>

        <div class="ms-1 mt-2" id="weight-option">
        <label class="fw-bold mb-2">Line Weight:</label>
        <select id="line-weight" class="w-100 rounded p-1">
          <option value="0">Normal</option>
          <option value="1">Bold</option>
        </select>
        </div>


        <div class="mb-3 mt-4" id="margin-options">
            <label class="fw-bold m-0">Margin</label>
            <div class="d-flex mt-2 row">
                <div class="col-sm-6">
                    <label class="font-weight-normal mb-2">Top:</label>
                    <input type="number" id="margin-top-input" class="w-100 rounded p-1 input-border">
                </div>
                <div class="col-sm-6">
                    <label class="font-weight-normal mb-2">Bottom:</label>
                    <input type="number" id="margin-bottom-input" class="w-100 rounded p-1 input-border">
                </div>
            </div>
            <div class="d-flex mt-2 row">
                <div class="col-sm-6">
                    <label class="font-weight-normal mb-2">Left:</label>
                    <input type="number" id="margin-left-input" class="w-100 rounded p-1 input-border">
                </div>
                <div class="col-sm-6">
                    <label class="font-weight-normal mb-2">Right:</label>
                    <input type="number" id="margin-right-input" class="w-100 rounded p-1 input-border">
                </div>
            </div>
        </div>

        <div class="mb-3 mt-4" id="global-margin-options">
            <label class="fw-bold m-0">Global Side Margins</label>
            <div class="d-flex mt-2 row">
                <div class="col-sm-6">
                    <label class="font-weight-normal mb-2">Left:</label>
                    <select id="global-margin-left" class="w-100 rounded p-1">
                      <option value="default" selected disabled class="opt-disabled">Select left margin</option>
                      <option value="default">Default</option>
                      <option value="0">0</option>
                    </select>
                </div>
                <div class="col-sm-6">
                    <label class="font-weight-normal mb-2">Right:</label>
                    <select id="global-margin-right" class="w-100 rounded p-1">
                      <option value="default" selected disabled class="opt-disabled">Select right margin</option>
                      <option value="default">Default</option>
                      <option value="0">0</option>
                    </select>
                </div>
            </div>
        </div>

    </div>
</div>
    `;

  };

  // Called during initialization to bind all draggable items to enable them to be draggable and initiate the drag-and-drop operation when it is dragged
  this.addDragstartHandler = function () {
    var draggableItems = this.builderElement.querySelectorAll(".drag-item");
    draggableItems.forEach(function (draggableItem) {
      draggableItem.addEventListener("dragstart", this.dragstartHandler.bind(this));
    }, this);
  };

  // Add dragover event listener to drop target element. Controls the behavior of the drop target during the drag operation.
  this.addDragoverHandler = function () {
    this.builderElement.addEventListener("dragover", this.dragoverHandler.bind(this));
  };

  // Responsible for adding the drop event listener to the drop target element
  this.addDropHandler = function () {
    this.builderElement.addEventListener("drop", this.dropHandler.bind(this));
  };

  // Add dragleave event listener to drop target element
  this.addDragleaveHandler = function () {
    this.dropTarget.addEventListener("dragleave", this.dragleaveHandler.bind(this));
  };

  // Updated dragleaveHandler function
  this.dragleaveHandler = function (event) {
    event.preventDefault();

    // Check if the drag event left the drop target
    if (event.target === this.dropTarget) {
      // Implement your logic for the dragged element being outside the drop target
      // This can include adding a class, resetting styles, or performing any other desired actions
      console.log("Element dragged outside the drop target");

      // For example, remove the orange border from the column element
      var targetColumn = this.dropTarget.querySelector(".col-50.dragover, .col-70.dragover, .col-30.dragover");
      if (targetColumn) {
        targetColumn.classList.remove("dragover");
      }
    }
  };

  // Responsible for adding the change event listener to each alignment radio button in the sidebar
  this.addAlignmentChangeHandler = function () {
    if (this.sidebar) {
      this.alignmentRadios.forEach(function (radio) {
        radio.addEventListener("change", self.changeAlignmentHandler.bind(this));
      }, this);
    }
  };

  this.addfontStyleChangeHandler = function () {
    if (this.sidebar) {
      this.fontStyleCheckbox.forEach(function (checkbox) {
        checkbox.addEventListener("change", this.changefontStyleHandler.bind(this));
      }, this);
    }
  };

  // Responsible for adding the change event listener to the font size select dropdown in the sidebar
  this.addFontSizeChangeHandler = function () {
    if (this.sidebar && this.fontSizeSelect) {
      this.fontSizeSelect.addEventListener("change", this.fontSizeChangeHandler.bind(this));
    }
  };


  // Responsible for handling the dragstart event when a draggable item is being dragged
  this.dragstartHandler = function (event) {
    event.dataTransfer.setData("text/plain", event.target.dataset.attribute);
  };

  // Executed when the dragover event occurs on the drop target element
  this.dragoverHandler = function (event) {
    event.preventDefault();
    // Add orange border to the column element when an item is dragged over it
    var targetColumn = event.target.closest(".col-50, .col-70, .col-30");
    if (targetColumn) {
      targetColumn.classList.add("dragover");
    }
  };

  // Executed when the drop event occurs on the drop target element
  this.dropHandler = function (event) {
    event.preventDefault();

    // Remove the orange border from the column element
    var targetColumn = event.target.closest(".col-50, .col-70, .col-30");
    if (targetColumn) {
      targetColumn.classList.remove("dragover");
    }

    // Get the data transferred during the drag operation
    var data = event.dataTransfer.getData("text/plain");

    // Append a dropped item to the drop target
    var dropItem = document.createElement("div");

    dropItem.classList.add("drop-item");
    // dropItem.setAttribute("contenteditable", "true");
    // dropItem.appendChild(draggedItem.cloneNode(true));

    var dataAttr = data;
    let column1, column2, parentDiv;
    switch (dataAttr) {
      case "h1":
        element = document.createElement("h1");
        element.innerHTML = "Heading 1";
        element.classList.add("m-0");
        element.setAttribute('data-placeholder', 'Heading 1');
        element.setAttribute("contenteditable", "true");
        dropItem.appendChild(element);
        dropItem.setAttribute("data-attribute", "h1");
        dropItem.addEventListener("click", function () {
          self.selectElement(dropItem);
          // self.swapElementHandler(dropItem);
        }.bind(this));
        break;
      case "h2":
      case "heading":
        element = document.createElement("h2");
        element.innerHTML = "Heading 2";
        element.classList.add("m-0");
        element.setAttribute('data-placeholder', 'Heading 2');
        element.setAttribute("contenteditable", "true");
        dropItem.appendChild(element);
        dropItem.setAttribute("data-attribute", "h2");
        dropItem.addEventListener("click", function () {
          this.selectElement(dropItem);
          // self.swapElementHandler(dropItem);
        }.bind(this));
        break;
      case "h3":
        element = document.createElement("h3");
        element.innerHTML = "Heading 3";
        element.classList.add("m-0");
        element.setAttribute('data-placeholder', 'Heading 3');
        element.setAttribute("contenteditable", "true");
        dropItem.appendChild(element);
        dropItem.setAttribute("data-attribute", "h3");
        dropItem.addEventListener("click", function () {
          this.selectElement(dropItem);
          // self.swapElementHandler(dropItem);
        }.bind(this));
        break;
      case "p":
      case "paragraph":
        element = document.createElement("p");
        element.innerHTML = "Paragraph";
        element.classList.add("m-0");
        element.setAttribute('data-placeholder', 'Paragraph');
        element.setAttribute("contenteditable", "true");
        dropItem.appendChild(element);
        dropItem.setAttribute("data-attribute", "paragraph");
        dropItem.addEventListener("click", function () {
          this.selectElement(dropItem);
          // self.swapElementHandler(dropItem);
        }.bind(this));
        break;
      case "image":
      case "img":
        element = document.createElement("label");
        var element_image = document.createElement('img');
        element.appendChild(element_image);
        element_image.src = "https://api.scan-reports.com/images/default-image.png";
        element_image.style.width = "100px";
        element.classList.add('img-label');

        let imageInput = document.createElement('input');
        imageInput.type = "file";
        imageInput.className = "image-upload";
        imageInput.name = "image-upload";
        imageInput.accept = "file";
        imageInput.addEventListener("change", this.imageChangeHandler.bind(this));
        element.appendChild(imageInput);

        let imageText = document.createElement("p");
        imageText.className = "img-text";
        imageText.innerHTML = "Click here to upload an image";

        let spanEle = document.createElement("span");
        spanEle.className = "help-text";
        spanEle.innerHTML = " (Max 2MB)";
        imageText.appendChild(spanEle);

        element.appendChild(imageText);

        dropItem.appendChild(element);
        dropItem.setAttribute("data-attribute", "image");
        dropItem.addEventListener("click", function () {
          this.selectElement(dropItem);
          // self.swapElementHandler(dropItem);
        }.bind(this));
        break;
      case '50:50':
        dropItem.innerHTML = '';

        parentDiv = document.createElement('div');
        column1 = document.createElement('div');
        column2 = document.createElement('div');

        parentDiv.classList.add('grid-row');
        column1.classList.add('col-50');
        column2.classList.add('col-50');

        column1.setAttribute('data-placeholder', 'Column 1');
        column2.setAttribute('data-placeholder', 'Column 2');
        dropItem.setAttribute("data-attribute", dataAttr);
        dropItem.setAttribute("data-handle", true);

        parentDiv.appendChild(column1);
        parentDiv.appendChild(column2);
        dropItem.appendChild(parentDiv);
        break;
      case '70:30':
        dropItem.innerHTML = '';

        parentDiv = document.createElement('div');
        column1 = document.createElement('div');
        column2 = document.createElement('div');

        parentDiv.classList.add('grid-row');
        column1.classList.add('col-70');
        column2.classList.add('col-30');

        column1.setAttribute('data-placeholder', 'Column 1');
        column2.setAttribute('data-placeholder', 'Column 2');
        dropItem.setAttribute("data-attribute", dataAttr);
        dropItem.setAttribute("data-handle", true);

        parentDiv.appendChild(column1);
        parentDiv.appendChild(column2);
        dropItem.appendChild(parentDiv);
        break;
      case '30:70':
        dropItem.innerHTML = '';

        parentDiv = document.createElement('div');
        column1 = document.createElement('div');
        column2 = document.createElement('div');

        parentDiv.classList.add('grid-row');
        column1.classList.add('col-30');
        column2.classList.add('col-70');

        column1.setAttribute('data-placeholder', 'Column 1');
        column2.setAttribute('data-placeholder', 'Column 2');
        dropItem.setAttribute("data-attribute", dataAttr);
        dropItem.setAttribute("data-handle", true);

        parentDiv.appendChild(column1);
        parentDiv.appendChild(column2);
        dropItem.appendChild(parentDiv);
        break;
      case 'hr':
        dropItem.innerHTML = '';

        var hrContainer = document.createElement('div');

        var hrElement = document.createElement('hr');
        hrElement.classList.add('hr-element');

        // Append the <hr> element to the container
        hrContainer.appendChild(hrElement);

        // Set the attribute and data-attribute values
        dropItem.setAttribute('data-attribute', dataAttr);
        hrContainer.setAttribute('data-attribute', dataAttr);

        // Add event listeners for selection and deletion
        hrContainer.addEventListener('click', function () {
          self.selectElement(hrContainer);
          // self.swapElementHandler(dropItem);
        }.bind(this));

        console.log(hrContainer, 'hrContainer');
        dropItem.appendChild(hrContainer);

        break;
      default:
        var imgTexT = dropItem.getElementsByClassName('img-text');
        if (imgTexT[0]) {
          imgTexT[0].style.display = '';
        }

        // Attach the imageChangeHandler to the dropped element
        var imageUploadInput = dropItem.querySelector(".image-upload");
        if (imageUploadInput) {
          imageUploadInput.addEventListener("change", this.imageChangeHandler.bind(this));
        }
        dropItem.setAttribute('data-attribute', dataAttr);
        dropItem.addEventListener("click", function () {
          this.selectElement(dropItem);
          // self.swapElementHandler(dropItem);
        }.bind(this));
        break;
    }

    // Add delete icon to the dropped element
    var deleteIcon = document.createElement("i");
    deleteIcon.classList.add("fa", "fa-trash", "fa-lg", "delete-icon", "cursor-pointer");
    deleteIcon.setAttribute("title", "Remove");
    deleteIcon.addEventListener("click", function () {
      if (dropItem.classList.contains('selected')) {
        dropItem.classList.remove('selected');
        this.selectedElement = null;
      }
      dropItem.remove();
    });

    // Show delete icon only when hovering over the specific element
    dropItem.addEventListener("mouseover", function () {
      deleteIcon.style.display = "block";
    });

    dropItem.addEventListener("mouseout", function () {
      deleteIcon.style.display = "none";
    });

    dropItem.appendChild(deleteIcon);

    // Generate a unique ID for the dropped element
    var uniqueId = "drop-item" + Date.now();
    dropItem.setAttribute("id", uniqueId); // Set the generated ID

    // Find the parent column to append the dropItem
    var parentColumn = event.target.closest(".col-50, .col-70, .col-30");
    if (parentColumn) {
      parentColumn.appendChild(dropItem);
    } else {
      let dropItems = this.dropTarget.getElementsByClassName("drop-item");
      if (dropItems.length > 0) {
        this.dropTarget.insertBefore(dropItem, dropItems[dropItems.length - 1].nextSibling);
      } else {
        this.dropTarget.appendChild(dropItem);
      }
    }

    dropItem.addEventListener("keydown", function (event) {
      if (event.key === "Enter") {
        event.preventDefault();
        console.log(dropItem, this, 'dropItem1');
        // Insert a line break
        document.execCommand("insertLineBreak");
      }
    });

    let placeholderTags = ['paragraph', 'heading', 'h3', 'h2', 'h1', '50:50', '70:30', '30:70'];
    let columnTags = ['50:50', '70:30', '30:70'];
    dropItem.addEventListener("keyup", function (event) {
      if (event.target.getAttribute("data-attribute") && placeholderTags.includes(event.target.getAttribute("data-attribute"))) {
        if (event.target.textContent == "") {
          switch (event.target.getAttribute("data-attribute")) {
            case "paragraph":
              event.target.querySelector("p").innerHTML = "";
              break;
            case "h1":
              event.target.querySelector("h1").innerHTML = "";
              break;
            case "h2":
            case "heading":
              event.target.querySelector("h2").innerHTML = "";
              break;
            case "h3":
              event.target.querySelector("h3").innerHTML = "";
              break;
            case '50:50':
            case '30:70':
            case '70:30':
              // event.target.querySelectorAll(".col-50").innerHTML = "";
              break;
          }
        }
      }

      if (columnTags.includes(event.target.getAttribute("data-attribute"))) {

        switch (event.target.getAttribute("data-attribute")) {
          case '50:50':
            event.target.querySelectorAll(".col-50").forEach((col) => {
              if (col.textContent == "") {
                col.innerHTML = "";
              }
            });
            break;
          case '70:30':
          case '30:70':
            if (event.target.querySelector(".col-70").textContent == "") {
              event.target.querySelector(".col-70").innerHTML = "";
            }
            if (event.target.querySelector(".col-30").textContent == "") {
              event.target.querySelector(".col-30").innerHTML = "";
            }
            break;
        }

        event.target.querySelectorAll("h1").forEach((h1) => {
          if (h1.textContent == "") {
            h1.innerHTML = "";
          }
        })
        event.target.querySelectorAll("h2").forEach((h2) => {
          if (h2.textContent == "") {
            h2.innerHTML = "";
          }
        })
        event.target.querySelectorAll("h3").forEach((h3) => {
          if (h3.textContent == "") {
            h3.innerHTML = "";
          }
        })

        event.target.querySelectorAll("p").forEach((p) => {
          if (p.textContent == "") {
            p.innerHTML = "";
          }
        })
      }
    });

    // Select the dropped element
    this.selectElement(dropItem);
    if (data == 'drag-item5') {
      this.changeAlignmentHandler('center');
    }
    // Scroll to the bottom of the drop target container
    this.dropTarget.scrollTop = this.dropTarget.scrollHeight;
  };

  // Remove swap elements when clicked outside of the selected element
  this.removeSwapElements = function () {
    let iconsRemove = this.dropTarget.querySelectorAll('.swap-icon');
    if (iconsRemove) {
      iconsRemove.forEach(element => {
        element.remove();
      });
    }
  }

  this.moveElementAbove = function (event) {
    let currElement = event.closest(".drop-item");
    let prevElement = currElement.previousElementSibling;

    if (prevElement) {
      currElement.parentNode?.insertBefore(currElement, prevElement);
    }

  }

  this.moveElementBelow = function (event) {
    let currElement = event.closest(".drop-item");
    let nextElement = currElement.nextElementSibling;

    if (nextElement) {
      currElement.parentNode?.insertBefore(nextElement, currElement);
    }

  }

  // Executed when the change event occurs on the image upload input
  this.imageChangeHandler = function (event) {
    var file = event.target.files[0];
    let fileList = event.target.files, imgFile;
    if (fileList.length > 0) {
      imgFile = fileList[0];
    }
    console.log(imgFile.size, file.size);

    const maxFileSizeInMB = 2;
    const maxFileSizeInKB = 1024 * 1024 * maxFileSizeInMB;

    if (file.size > maxFileSizeInKB) {
      alert(`Please select a file that is ${maxFileSizeInMB}MB or less.`);
      return;
    }

    const formData = new FormData();
    formData.append("file", imgFile);

    let access_token = localStorage.getItem('access_token');
    const xhr = new XMLHttpRequest();
    xhr.open('post', this.envURL + '/get_uploaded_file_url');
    xhr.responseType = "json";
    xhr.setRequestHeader('Authorization', 'Bearer ' + access_token);
    xhr.send(formData);
    xhr.onload = () => {
      if (xhr.readyState == 4 && xhr.status == 200) {
        let res = xhr.response;

        if (file && res.imageUrl) {
          var reader = new FileReader();
          reader.onload = function (e) {
            var image = new Image();
            image.src = e.target.result;
            image.onload = function () {
              let width = image.naturalWidth;
              if (this.selectedElement) {
                if (this.selectedElement.querySelector("img")) {
                  if (this.selectedElement.querySelector("img").getAttribute("style")) {
                    this.selectedElement.querySelector("img").removeAttribute("style");
                  }

                  this.selectedElement.querySelector("img").src = res.imageUrl;
                  if (width > 600) {
                    this.selectedElement.querySelector("img").style.width = "600px";
                  }

                } else {
                  var imgParent = this.selectedElement.querySelector("label");
                  imgParent.prepend(document.createElement("img"));
                  if (imgParent.querySelector('svg')) {
                    imgParent.querySelector('svg').remove();
                  }
                  this.selectedElement.querySelector("img").src = res.imageUrl;
                  if (width > 600) {
                    this.selectedElement.querySelector("img").style.width = "600px";
                  }
                }
              }
            }.bind(this);
          }.bind(this);
          reader.readAsDataURL(file);
        }
      }
    };
  };

  this.addWidthChangeHandler = function () {
    const widthInput = document.getElementById("width-input");
    widthInput.addEventListener("change", () => {
      console.log(this.selectedElement.getAttribute('data-attribute'), 'attr');
      if (this.selectedElement) {
        let dataAttr = this.selectedElement.getAttribute('data-attribute');
        switch (dataAttr) {
          case 'image':
            this.selectedElement.getElementsByTagName('img')[0].style.width = widthInput.value + "px";
            break;
          case 'hr':
            this.selectedElement.getElementsByTagName('hr')[0].style.width = widthInput.value + "cm";
            break;
        }
      }
    });
  };

  this.addHeightChangeHandler = function () {
    const heightInput = document.getElementById("height-input");
    heightInput.addEventListener("change", () => {
      if (this.selectedElement) {
        this.selectedElement.getElementsByTagName('img')[0].style.height = heightInput.value + "px";
      }
    });
  };

  this.addImagePositionChangeHandler = function () {
    const imgPos = document.getElementById("image-position");
    imgPos.addEventListener("change", () => {
      // let ele = this.selectedElement.querySelector('.drop-item');
      if (this.selectedElement) {
        console.log(imgPos.value, this.selectedElement.style);
        if (imgPos && imgPos.value == "behind") {
          this.selectedElement.style.position = "absolute";
          this.selectedElement.style.opacity = "0.7";
        } else {
          this.selectedElement.style.position = "relative";
          this.selectedElement.style.removeProperty("opacity");
        }
        // this.selectedElement.getElementsByTagName('img')[0].style.height = heightInput.value + "px";
      }
    });
  };

  this.addWeightChangeHandler = function () {
    const weightInput = document.getElementById("line-weight");
    weightInput.addEventListener("change", () => {
      if (this.selectedElement) {
        let hrTags = this.dropTarget.querySelectorAll('hr');
        console.log(weightInput);
        if (weightInput.value == 1) {
          hrTags.forEach((tag) => {
            tag.style.height = "2.5px";
          });
        } else {
          hrTags.forEach((tag) => {
            tag.style.height = "1px";
          });
        }
      }
    });
  };

  // this.getSelectionText = function () {
  //   var text = "";
  //   if (window.getSelection) {
  //     text = window.getSelection().toString();
  //   } else if (document.selection && document.selection.type != "Control") {
  //     text = document.selection.createRange().text;
  //   }
  //   return text;
  // }

  this.addMarginChangeHandler = function () {
    const marginTopInput = document.getElementById("margin-top-input");
    const marginBottomInput = document.getElementById("margin-bottom-input");
    const marginLeftInput = document.getElementById("margin-left-input");
    const marginRightInput = document.getElementById("margin-right-input");
    marginTopInput.addEventListener("change", () => {
      console.log(this.selectedElement.getAttribute("data-attribute"));

      if (this.selectedElement) {
        if (this.selectedElement.getAttribute("data-attribute") == "image") {
          this.selectedElement.querySelector('img').style.setProperty("margin-top", marginTopInput.value + "px", "important");
        } else {
          console.log(marginTopInput.value, 'margin-top');
          if (marginTopInput.value) {
            this.selectedElement.firstChild.style.setProperty("margin-top", marginTopInput.value + "px", "important");
          }
          else {
            this.selectedElement.firstChild.style.setProperty("margin-top", "0px", "important");
          }
        }
      }
    });

    marginBottomInput.addEventListener("change", () => {
      if (this.selectedElement) {
        if (this.selectedElement.getAttribute("data-attribute") == "image") {
          this.selectedElement.querySelector('img').style.setProperty("margin-bottom", marginBottomInput.value + "px", "important");
        } else {
          if (marginBottomInput.value) {
            this.selectedElement.firstChild.style.setProperty("margin-bottom", marginBottomInput.value + "px", "important");
          }
          else {
            this.selectedElement.firstChild.style.setProperty("margin-bottom", "0px", "important");
          }
        }
      }
    });
    marginLeftInput.addEventListener("change", () => {
      if (this.selectedElement) {
        if (this.selectedElement.getAttribute("data-attribute") == "image") {
          this.selectedElement.querySelector('img').style.setProperty("margin-left", marginLeftInput.value + "px", "important");
        } else {
          if (marginLeftInput.value) {
            this.selectedElement.firstChild.style.setProperty("margin-left", marginLeftInput.value + "px", "important");
          } else {
            this.selectedElement.firstChild.style.setProperty("margin-left", "0px", "important");
          }
        }
      }
    });
    marginRightInput.addEventListener("change", () => {
      if (this.selectedElement) {
        if (this.selectedElement.getAttribute("data-attribute") == "image") {
          this.selectedElement.querySelector('img').style.setProperty("margin-right", marginRightInput.value + "px", "important");
        } else {
          if (marginRightInput.value) {
            this.selectedElement.firstChild.style.setProperty("margin-right", marginRightInput.value + "px", "important");
          } else {
            this.selectedElement.firstChild.style.setProperty("margin-right", "0px", "important");
          }
        }

      }
    });
  };

  this.addSectionColorChangeHandler = function () {
    const colorPickerInput = document.getElementById("bg-color-picker");
    colorPickerInput.addEventListener("change", () => {
      if (this.selectedElement) {
        this.selectedElement.firstChild.style.backgroundColor = document.getElementById("bg-color-picker").value;
      }
    });
  };

  this.addTextColorChangeHandler = function () {
    const colorPickerInput = document.getElementById("text-color-picker");

    colorPickerInput.addEventListener("change", () => {
      if (this.selectedElement) {
        let dataAttr = this.selectedElement.getAttribute("data-attribute");

        switch (dataAttr) {
          case "hr":
            const color = document.getElementById("text-color-picker").value;
            this.selectedElement.getElementsByTagName("hr")[0].style.backgroundColor = color;
            break;
          case "paragraph":
          case "p":
          case "h1":
          case "h2":
          case "h3":
            this.selectedElement.firstChild.style.color = document.getElementById("text-color-picker").value;
            break;
          default:
            break;
        }
      }
    });
  };

  this.moveElementsChangeHandler = function () {
    const moveElementUp = document.getElementById("move-up");
    const moveElementDown = document.getElementById("move-down");

    moveElementUp.addEventListener("click", () => {
      if (this.selectedElement) {
        self.moveElementAbove(this.selectedElement);
      }
    });

    moveElementDown.addEventListener("click", () => {
      if (this.selectedElement) {
        self.moveElementBelow(this.selectedElement);
      }
    });
  }

  // Executed when the change event occurs on an alignment radio button
  this.changeAlignmentHandler = function (event) {
    if (event.target && event.target.value) {
      var alignmentOption = event.target.value;
    } else {
      var alignmentOption = event;
    }

    if (event.target && event.target.id) {
      var radioOption = document.getElementById(event.target.id);
      if (radioOption.checked) {
        document.getElementById(event.target.id).nextElementSibling.classList.add('dark-font-style');

        var alignRadioButtons = document.querySelectorAll('input[name="alignment"]');

        for (var i = 0; i < alignRadioButtons.length; i++) {
          if (alignRadioButtons[i].id == event.target.id) {
            document.getElementById(alignRadioButtons[i].id).nextElementSibling.classList.add('dark-font-style')
          } else {
            document.getElementById(alignRadioButtons[i].id).nextElementSibling.classList.remove('dark-font-style')
          }
        }
      }
    }

    if (this.selectedElement) {
      let dataAttr = this.selectedElement.getAttribute('data-attribute');
      switch (dataAttr) {
        case 'hr':
          this.selectedElement.firstChild.style.textAlign = alignmentOption;
          this.selectedElement.getElementsByTagName('hr')[0].setAttribute('align', alignmentOption);
          break;
        default:
          this.selectedElement.firstChild.style.textAlign = alignmentOption;
          break;
      }
    }
  };

  // Executed when the change event occurs on an alignment radio button
  this.changefontStyleHandler = function (event) {
    var fontStyleOptions = Array.from(this.fontStyleCheckbox).map((checkbox) =>
      checkbox.checked ? checkbox.value : null
    );

    console.log(fontStyleOptions, 'style options');
    if (this.selectedElement) {

      fontStyleOptions.forEach((style) => {

      })
      this.selectedElement.firstChild.style.fontWeight = fontStyleOptions.includes("bold") ? "bold" : "normal";
      this.selectedElement.firstChild.style.fontStyle = fontStyleOptions.includes("italic") ? "italic" : "normal";
      this.selectedElement.firstChild.style.textDecoration = fontStyleOptions.includes("underline") ? "underline" : "none";

      var checkboxOption = document.getElementById(event.target.id);
      if (checkboxOption.checked) {
        document.getElementById(event.target.id).nextElementSibling.classList.add('dark-font-style');
      } else {
        document.getElementById(event.target.id).nextElementSibling.classList.remove('dark-font-style');
      }
    }
  };


  // Executed when the font size select dropdown is changed in the sidebar
  this.fontSizeChangeHandler = function (event) {
    var selectedFontSize = event.target.value;
    if (this.selectedElement) {
      this.selectedElement.firstChild.style.fontSize = selectedFontSize + "px";
    }
  };

  // Mark the element as selected on click
  this.selectElement = function (element) {
    if (this.selectedElement) {
      this.selectedElement.classList.remove("selected");
    }
    this.selectedElement = element;
    let dataAttr = this.selectedElement.getAttribute("data-attribute");
    if (dataAttr) {
      switch (dataAttr) {
        case "heading":
        case 'paragraph':
        case 'p':
          // Show style options in right sidebar
          document.getElementById("align-items-options").classList.remove("hidden");
          document.getElementById("font-size-options").classList.remove("hidden");
          document.getElementById("font-style-options").classList.remove("hidden");
          document.getElementById("text-color-options").classList.remove("hidden");
          document.getElementById("section-color-options").classList.remove("hidden");
          document.getElementById("margin-options").classList.remove("hidden");

          // Hide style options from right sidebar
          document.getElementById("global-margin-options").classList.add("hidden");
          document.getElementById("height-width-options").classList.add("hidden");
          document.getElementById("image-position-option").classList.add("hidden");
          document.getElementById("weight-option").classList.add("hidden");
          break;
        case "h1":
        case "h2":
        case "h3":
          // Show style options in right sidebar
          document.getElementById("align-items-options").classList.remove("hidden");
          document.getElementById("font-style-options").classList.remove("hidden");
          document.getElementById("text-color-options").classList.remove("hidden");
          document.getElementById("section-color-options").classList.remove("hidden");
          document.getElementById("margin-options").classList.remove("hidden");

          // Hide style options from right sidebar
          document.getElementById("font-size-options").classList.add("hidden");
          document.getElementById("global-margin-options").classList.add("hidden");
          document.getElementById("height-width-options").classList.add("hidden");
          document.getElementById("image-position-option").classList.add("hidden");
          document.getElementById("weight-option").classList.add("hidden");
          break;
        case 'image':
        case 'img':
          // Show style options in right sidebar
          document.getElementById("align-items-options").classList.remove("hidden");
          document.getElementById("height-width-options").classList.remove("hidden");
          document.getElementById("image-position-option").classList.remove("hidden");
          document.getElementById("section-color-options").classList.remove("hidden");
          document.getElementById("margin-options").classList.remove("hidden");
          document.getElementById("height-option").classList.remove("hidden");

          // Hide style options from right sidebar
          document.getElementById("text-color-options").classList.add("hidden");
          document.getElementById("font-size-options").classList.add("hidden");
          document.getElementById("global-margin-options").classList.add("hidden");
          document.getElementById("font-style-options").classList.add("hidden");
          document.getElementById("weight-option").classList.add("hidden");
          break;
        case 'hr':
          // Show style options in right sidebar
          document.getElementById("align-items-options").classList.remove("hidden");
          document.getElementById("text-color-options").classList.remove("hidden");
          document.getElementById("weight-option").classList.remove("hidden");

          // Hide style options from right sidebar
          document.getElementById("height-option").classList.add("hidden");
          document.getElementById("image-position-option").classList.add("hidden");
          document.getElementById("section-color-options").classList.add("hidden");
          document.getElementById("margin-options").classList.add("hidden");
          document.getElementById("font-size-options").classList.add("hidden");
          document.getElementById("font-style-options").classList.add("hidden");
          document.getElementById("font-style-options").classList.add("hidden");
          document.getElementById("global-margin-options").classList.add("hidden");
          break;
        default:
          // Show style options in right sidebar
          document.getElementById("align-items-options").classList.remove("hidden");
          document.getElementById("text-color-options").classList.remove("hidden");
          document.getElementById("section-color-options").classList.remove("hidden");
          document.getElementById("margin-options").classList.remove("hidden");
          document.getElementById("font-size-options").classList.remove("hidden");
          document.getElementById("font-style-options").classList.remove("hidden");
          document.getElementById("height-width-options").classList.remove("hidden");
          document.getElementById("image-position-option").classList.remove("hidden");
          document.getElementById("global-margin-options").classList.remove("hidden");
          document.getElementById("weight-option").classList.add("hidden");
          break;
      }

    }
    this.selectedElement.classList.add("selected");
    var currentAlignment = this.selectedElement.style.textAlign;
    this.alignmentIndex = this.alignmentOptions.indexOf(currentAlignment);
    this.alignmentRadios.forEach(function (radio) {
      radio.checked = false;
    });
    if (this.alignmentIndex !== -1) {
      this.alignmentRadios[this.alignmentIndex].checked = true;
    }

    this.fontStyleCheckbox.forEach(function (checkbox) {
      checkbox.checked = false;
      checkbox.nextElementSibling.classList.remove('dark-font-style');
    });
    if (this.fontStyleIndex !== -1) {
      this.fontStyleCheckbox[this.fontStyleIndex].checked = true;
    }
  };


  this.getElementItems = function (element) {
    var elementItem = {};
    let dataAttr = element.getAttribute('data-attribute');
    var elementType = element.firstChild.tagName.toLowerCase();

    switch (dataAttr) {
      case 'image':
        elementItem.type = element.firstChild.firstElementChild.tagName.toLowerCase();
        // Check if element has a URL attribute (e.g., for images)
        var url = element.firstChild.firstElementChild.getAttribute("src");
        // if (url) {
        //   elementItem.url = 'https://imgur.com/K0Ljk0f.png';
        // }

        elementItem.url = url;
        // Get styling attributes
        var elementStyles = element.firstChild.firstElementChild.style;
        var parentElementStyles = element.querySelector("label").style;

        var styling = {};

        if (element.style.position && element.style.position == "absolute") {
          styling.position = "behind";
        } else {
          styling.position = "in-front";
        }

        if (elementStyles.width) {
          styling.width = elementStyles.width;
        }

        if (elementStyles.height) {
          styling.height = elementStyles.height;
        }

        if (elementStyles.textAlign) {
          styling.textAlign = elementStyles.textAlign;
        }

        if (parentElementStyles.width) {
          styling.width = parentElementStyles.width;
        }

        if (parentElementStyles.height) {
          styling.height = parentElementStyles.height;
        }

        if (parentElementStyles.textAlign) {
          styling.textAlign = parentElementStyles.textAlign;
        }

        if (elementStyles.marginTop) {
          styling.marginTop = elementStyles.marginTop;
        }
        if (elementStyles.marginBottom) {
          styling.marginBottom = elementStyles.marginBottom;
        }
        if (elementStyles.marginLeft) {
          styling.marginLeft = elementStyles.marginLeft;
        }
        if (elementStyles.marginRight) {
          styling.marginRight = elementStyles.marginRight;
        }

        if (parentElementStyles.backgroundColor) {
          let backgroundColor = self.getHexColor(elementStyles.backgroundColor);
          styling.backgroundColor = backgroundColor;
        }
        break;
      default:
        elementItem.type = elementType;

        // Check if element has a URL attribute (e.g., for images)
        var url = element.firstChild.getAttribute("src");
        if (url) {
          elementItem.url = url;
        }

        // Get styling attributes
        var elementStyles = element.firstChild.style;
        var parentElementStyles = element.style;

        var styling = {};

        if (elementStyles.textAlign) {
          styling.textAlign = elementStyles.textAlign;
        }

        if (elementStyles.fontWeight) {
          styling.fontWeight = elementStyles.fontWeight;
        }

        if (elementStyles.textDecoration) {
          styling.textDecoration = elementStyles.textDecoration;
        }

        if (elementStyles.fontStyle) {
          styling.fontStyle = elementStyles.fontStyle;
        }

        if (elementStyles.fontSize) {
          styling.fontSize = elementStyles.fontSize;
        }

        if (elementStyles.textAlign) {
          styling.textAlign = elementStyles.textAlign;
        }

        if (elementStyles.width) {
          styling.width = elementStyles.width;
        }

        if (elementStyles.height) {
          styling.height = elementStyles.height;
        }

        if (parentElementStyles.width) {
          styling.width = parentElementStyles.width;
        }

        if (parentElementStyles.height) {
          styling.height = parentElementStyles.height;
        }

        if (elementStyles.marginTop) {
          styling.marginTop = elementStyles.marginTop;
        }
        if (elementStyles.marginBottom) {
          styling.marginBottom = elementStyles.marginBottom;
        }
        if (elementStyles.marginLeft) {
          styling.marginLeft = elementStyles.marginLeft;
        }
        if (elementStyles.marginRight) {
          styling.marginRight = elementStyles.marginRight;
        }

        if (elementStyles.backgroundColor) {
          let backgroundColor = self.getHexColor(elementStyles.backgroundColor);
          styling.backgroundColor = backgroundColor;
        }

        if (elementStyles.color) {
          styling.color = self.getHexColor(elementStyles.color);
        }

        break;
    }

    // Get class attributes
    var tempClasses = element.firstChild.getAttribute("class");
    if (tempClasses) {
      const temp = tempClasses.split(" ");
      const classes = temp.filter(x => x !== 'img-label');
      elementItem.class = classes;
    }

    if (elementItem.type === "heading" || elementItem.type === "paragraph") {
      var paragraphStyles = element.querySelector("p").style;

      if (paragraphStyles.textAlign) {
        element.paragraph_styling = {
          "text-align": paragraphStyles.textAlign
        };
      }
    }

    if (element.firstChild.textContent) {

      var droppedText = element.firstChild.innerHTML;

      // Remove leading and trailing white spaces
      droppedText = droppedText.trim();

      // Save the dropped element to JSON
      elementItem.displayText = droppedText;

      // Clean up consecutive line breaks
      droppedText = droppedText.replace(/<br\s*\/?>\s*<br\s*\/?>/g, "<br>");

      // Save the dropped element to JSON
      elementItem.text = droppedText;

      // var droppedText = dropItem.firstChild.innerHTML;

      //   // Remove leading and trailing white spaces
      //   droppedText = droppedText.trim();
      //   element.displayText = droppedText;

      //   // Clean up consecutive line breaks
      //   droppedText = droppedText.replace(/<br\s*\/?>\s*<br\s*\/?>/g, "<br>");

      //   // Save the dropped element to JSON
      //   element.text = droppedText;
    }

    elementItem.styling = styling;

    console.log(elementItem, 'elementItem');
    return elementItem;
  };

  this.getElementsJSON = function () {


    this.dropTarget = this.builderElement.querySelector('#drop-target');
    var dropItems = this.dropTarget.querySelectorAll("#drop-target > .drop-item");



    console.log(dropItems, 'dropItems');

    var elements = Array.from(dropItems).map(function (dropItem) {

      var element = {};
      console.log(dropItem.getAttribute('data-attribute'));
      let dataAttr = dropItem.getAttribute('data-attribute');
      var elementType = dropItem.firstChild.tagName.toLowerCase();

      switch (dataAttr) {
        case 'image':
          element.type = dropItem.firstChild.firstElementChild.tagName.toLowerCase();
          // Check if element has a URL attribute (e.g., for images)
          var url = dropItem.firstChild.firstElementChild.getAttribute("src");
          if (url) {
            element.url = url;
          }
          // Get styling attributes
          var elementStyles = dropItem.firstChild.firstElementChild.style;
          var parentElementStyles = dropItem.querySelector("label").style;

          var styling = {};

          console.log(elementStyles, 'imgstyle');
          if (dropItem.style.position && dropItem.style.position == "absolute") {
            styling.position = "behind";
          } else {
            styling.position = "in-front";
          }

          if (elementStyles.width) {
            styling.width = elementStyles.width;
          }

          if (elementStyles.height) {
            styling.height = elementStyles.height;
          }

          if (elementStyles.textAlign) {
            styling.textAlign = elementStyles.textAlign;
          }

          if (elementStyles.marginTop) {
            styling.marginTop = elementStyles.marginTop;
          }
          if (elementStyles.marginBottom) {
            styling.marginBottom = elementStyles.marginBottom;
          }
          if (elementStyles.marginLeft) {
            styling.marginLeft = elementStyles.marginLeft;
          }
          if (elementStyles.marginRight) {
            styling.marginRight = elementStyles.marginRight;
          }

          if (parentElementStyles.width) {
            styling.width = parentElementStyles.width;
          }

          if (parentElementStyles.height) {
            styling.height = parentElementStyles.height;
          }

          if (parentElementStyles.textAlign) {
            styling.textAlign = parentElementStyles.textAlign;
          }


          if (parentElementStyles.backgroundColor) {
            let backgroundColor = self.getHexColor(elementStyles.backgroundColor);
            styling.backgroundColor = backgroundColor;
          }

          break;
        case '50:50':
        case '70:30':
        case '30:70':
          console.log(dropItem, 'dropItem');
          element.type = dataAttr;
          element.column1 = [];
          element.column2 = [];

          column1Elements = dropItem.querySelector('.grid-row').firstChild;
          column2Elements = dropItem.querySelector('.grid-row').firstChild.nextSibling;

          let ele1 = column1Elements.querySelectorAll('.drop-item');
          let ele2 = column2Elements.querySelectorAll('.drop-item');

          console.log(ele1, ele2);

          ele1.forEach((ele) => {
            let items;
            items = self.getElementItems(ele);
            // items.url = 'https://imgur.com/K0Ljk0f.png';
            console.log(items, 'items');
            console.log(element.column1, 'col 1'); // Debug
            element.column1.push(items);
          });

          ele2.forEach((ele) => {
            items = self.getElementItems(ele);
            // items.url = 'https://imgur.com/ZvRb9H8.png';
            element.column2.push(items);
          });
          break;
        case 'hr':
          element.type = 'hr';
          let item = dropItem.getElementsByTagName('hr')[0];
          if (item.align) {
            element.textAlign = item.align;
          }

          console.log(item, 'hrite');
          if (item.style.backgroundColor) {
            element.color = self.getHexColor(item.style.backgroundColor);
          }

          console.log(item, item.style);
          if (item.style.width) {
            element.width = item.style.width;
          }

          element.weight = document.getElementById("line-weight").value;

          break;
        default:
          element.type = elementType;

          // Check if element has a URL attribute (e.g., for images)
          var url = dropItem.firstChild.getAttribute("src");
          if (url) {
            element.url = url;
          }

          // Get styling attributes
          var elementStyles = dropItem.firstChild.style;
          var parentElementStyles = dropItem.style;

          var styling = {};

          if (elementStyles.textAlign) {
            styling.textAlign = elementStyles.textAlign;
          }

          if (elementStyles.fontWeight) {
            styling.fontWeight = elementStyles.fontWeight;
          }

          if (elementStyles.textDecoration) {
            styling.textDecoration = elementStyles.textDecoration;
          }

          if (elementStyles.fontStyle) {
            styling.fontStyle = elementStyles.fontStyle;
          }

          if (elementStyles.fontSize) {
            styling.fontSize = elementStyles.fontSize;
          }

          if (elementStyles.textAlign) {
            styling.textAlign = elementStyles.textAlign;
          }

          if (elementStyles.width) {
            styling.width = elementStyles.width;
          }

          if (elementStyles.height) {
            styling.height = elementStyles.height;
          }

          if (parentElementStyles.width) {
            styling.width = parentElementStyles.width;
          }

          if (parentElementStyles.height) {
            styling.height = parentElementStyles.height;
          }

          if (elementStyles.marginTop) {
            styling.marginTop = elementStyles.marginTop;
          }
          if (elementStyles.marginBottom) {
            styling.marginBottom = elementStyles.marginBottom;
          }
          if (elementStyles.marginLeft) {
            styling.marginLeft = elementStyles.marginLeft;
          }
          if (elementStyles.marginRight) {
            styling.marginRight = elementStyles.marginRight;
          }

          if (elementStyles.backgroundColor) {
            let backgroundColor = self.getHexColor(elementStyles.backgroundColor);
            styling.backgroundColor = backgroundColor;
          }

          if (elementStyles.color) {
            styling.color = self.getHexColor(elementStyles.color);
          }
          break;
      }

      // Get class attributes
      var tempClasses = dropItem.firstChild.getAttribute("class");
      if (tempClasses) {
        const temp = tempClasses.split(" ");
        const classes = temp.filter(x => x !== 'img-label');
        element.class = classes;

      }

      if (element.type === "heading" || element.type === "paragraph") {
        var paragraphStyles = dropItem.querySelector("p").style;

        if (paragraphStyles.textAlign) {
          element.paragraph_styling = {
            "text-align": paragraphStyles.textAlign
          };
        }
      }

      if (dropItem.firstChild.textContent) {
        console.log('Text Content', dropItem.firstChild.textContent);
        var droppedText = dropItem.firstChild.innerHTML;

        // Remove leading and trailing white spaces
        droppedText = droppedText.trim();
        element.displayText = droppedText;

        // Clean up consecutive line breaks
        droppedText = droppedText.replace(/<br\s*\/?>\s*<br\s*\/?>/g, "<br>");

        // Save the dropped element to JSON
        element.text = droppedText;
      }
      else {

        // Save the dropped element to JSON
        element.text = " ";
        element.displayText = " ";
      }

      element.styling = styling;

      return element;
    });

    globalStyles = {
      marginLeft: document.getElementById("global-margin-left").value,
      marginRight: document.getElementById("global-margin-right").value
    }

    return JSON.stringify({ elements: elements, globalStyles: globalStyles }, null, 2);
  };

  this.getHexColor = function (rgbColor) {
    // Parse the RGB components from the color value
    var rgbComponents = rgbColor.match(/\d+/g);

    // Convert RGB components to hex
    var r = parseInt(rgbComponents[0]);
    var g = parseInt(rgbComponents[1]);
    var b = parseInt(rgbComponents[2]);
    var hexColor = self.rgbToHex(r, g, b);

    return hexColor;
  }

  this.rgbToHex = function (r, g, b) {
    // Convert each RGB component to a two-digit hex value
    var rHex = r.toString(16).padStart(2, '0');
    var gHex = g.toString(16).padStart(2, '0');
    var bHex = b.toString(16).padStart(2, '0');

    // Concatenate the hex values
    var hexValue = '#' + rHex + gHex + bHex;

    return hexValue;
  }

}
